import { useCallback, useEffect, useMemo, useState } from "react";
import { formatDate, twoChar } from "../utils/format";
import { fixSize, GC, GS, GTS } from "../utils/style";
import axios from "axios";
import { useLocation } from "react-router";
import Modal from "../templates/Modal";
import { LineMarkSeries as LineMark, XYPlot } from "react-vis";
const LineMarkSeries = LineMark as any;
const newWindow = window as any;

// const tempData020 = [
//   {
//     i_serial_no: null,
//     hist_temp: "21.5",
//     hist_humi: "53.9",
//     hist_radi: "0.00",
//     hist_time: "2020101611100000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "21.6",
//     hist_humi: "54.3",
//     hist_radi: "0.00",
//     hist_time: "2020101611200000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "18.8",
//     hist_humi: "56.5",
//     hist_radi: "0.12",
//     hist_time: "2020101611300000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-4.1",
//     hist_humi: "65.6",
//     hist_radi: "0.16",
//     hist_time: "2020101611400000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-5.4",
//     hist_humi: "63.5",
//     hist_radi: "0.14",
//     hist_time: "2020101611500000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-6.2",
//     hist_humi: "62.9",
//     hist_radi: "0.13",
//     hist_time: "2020101612000000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-6.5",
//     hist_humi: "64.1",
//     hist_radi: "0.13",
//     hist_time: "2020101612100000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-4.3",
//     hist_humi: "66.1",
//     hist_radi: "0.15",
//     hist_time: "2020101614100000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-6.0",
//     hist_humi: "64.9",
//     hist_radi: "0.14",
//     hist_time: "2020101614200000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-7.2",
//     hist_humi: "64.3",
//     hist_radi: "0.11",
//     hist_time: "2020101614300000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-8.1",
//     hist_humi: "63.1",
//     hist_radi: "0.13",
//     hist_time: "2020101614400000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-7.5",
//     hist_humi: "68.1",
//     hist_radi: "0.14",
//     hist_time: "2020101614500000",
//   },
//   {
//     i_serial_no: null,
//     hist_temp: "-8.3",
//     hist_humi: "65.1",
//     hist_radi: "0.14",
//     hist_time: "2020101615000000",
//   },
// ];

export default function GoodsInfo() {
  const [state, setState] = useState<any>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isMapVisibled, setIsMapVisibled] = useState(false);
  const [isMapLoadComplete, setIsMapLoadComplete] = useState(false);
  const [isGraphVisibled, setIsGraphVisibled] = useState(false);
  const [isGraphLoadComplete, setIsGraphLoadComplete] = useState(false);
  const location = useLocation();
  const serialNo = (() => {
    if (location.pathname.split("goods-info/").length === 1) {
      // new - path
      return location.search.split("serial_no=")[1];
    } else {
      // old - path
      return location.pathname.split("goods-info/")[1];
    }
  })();

  useEffect(() => {
    const init = async () => {
      try {
        // const result = await axios.all([
        //   axios({
        //     url: "https://nscc.bpnsolution.com:8081/SCC/app/app010r/getData.do?i_serial_no=" + serialNo,
        //     method: "get",
        //   }),
        //   axios({
        //     url: "https://nscc.bpnsolution.com:8081/SCC/app/app060r/getData.do?i_serial_no=" + serialNo,
        //     method: "get",
        //   }),
        //   axios({
        //     url: "https://nscc.bpnsolution.com:8081/SCC/app/app080r/getData.do?i_serial_no=" + serialNo,
        //     method: "get",
        //   }),
        // ]);
        const result = await axios.all([
          axios({
            url: "https://kiosk.questx.co.kr/good?i_serial_no=" + serialNo,
            method: "get",
            timeout: 60000,
            timeoutErrorMessage: "timeout",
          }),
          // axios({
          //   url: "https://kiosk.questx.co.kr/tempAndHumi?i_serial_no=" + serialNo,
          //   method: "get",
          // }),
          axios({
            url: "https://kiosk.questx.co.kr/history?i_serial_no=" + serialNo,
            method: "get",
            timeout: 60000,
            timeoutErrorMessage: "timeout",
          }),
        ]);
        const data010 = result[0].data.data[0];
        const data080 = result[1].data.data;
        // const data020 = result[1].data.data;
        // const data080 = result[2].data.data;
        if (!data010 || !data080) {
          if (newWindow.ReactNativeWebView) {
            newWindow.ReactNativeWebView.postMessage(
              JSON.stringify({
                channel: "onErr",
                data: "해당 상품의 물류정보가 생성되지 않았습니다.",
              })
            );
          }
        } else {
          setState({ ...state, data010, data080 });
        }
      } catch (err) {
        if (newWindow.ReactNativeWebView) {
          switch (String(err)) {
            case "Error: timeout":
              newWindow.ReactNativeWebView.postMessage(
                JSON.stringify({
                  channel: "onErr",
                  data: "로딩 시간이 초과되었습니다. 잠시 후 다시 시도해 주세요.",
                })
              );
              break;
            default:
              newWindow.ReactNativeWebView.postMessage(
                JSON.stringify({
                  channel: "onErr",
                  data: String(err),
                })
              );
              break;
          }
        }
      } finally {
        if (newWindow.ReactNativeWebView) {
          newWindow.ReactNativeWebView.postMessage(
            JSON.stringify({ channel: "onLoadEnd" })
          );
        }
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (isGraphVisibled) {
      if (!state?.data020) {
        const getGraphData = async () => {
          try {
            const result = await axios({
              url:
                "https://kiosk.questx.co.kr/tempAndHumi?i_serial_no=" +
                serialNo,
              method: "get",
              timeout: 60000,
              timeoutErrorMessage: "timeout",
            });
            const data020 = result.data.data;
            if (!data020 || data020.length === 0) {
              if (newWindow.ReactNativeWebView) {
                newWindow.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    channel: "onPopupErr",
                    data: "해당 상품의 온습도 정보가 생성되지 않았습니다.",
                  })
                );
              } else {
                alert("해당 상품의 온습도 정보가 생성되지 않았습니다.");
              }
              onGraphCloseClick();
            } else {
              const newData020 = formatGraphData(data020);
              setState({ ...state, data020: newData020 });
            }
          } catch (err) {
            if (newWindow.ReactNativeWebView) {
              switch (String(err)) {
                case "Error: timeout":
                  newWindow.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      channel: "onErr",
                      data: "로딩 시간이 초과되었습니다. 잠시 후 다시 시도해 주세요.",
                    })
                  );
                  break;
                default:
                case "Error: Request failed with status code 404":
                  newWindow.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      channel: "onPopupErr",
                      data: String(err),
                    })
                  );
                  break;
              }
            } else {
              if (String(err) === "Error: timeout") {
                alert(
                  "로딩 시간이 초과되었습니다. 잠시 후 다시 시도해 주세요."
                );
              } else {
                alert(String(err));
              }
            }
            onGraphCloseClick();
          }
        };
        getGraphData();
      } else if (!isGraphLoadComplete) {
        setIsGraphLoadComplete(true);
      }
    }
  }, [isGraphVisibled, isGraphLoadComplete, state]);

  useEffect(() => {
    if (isMapVisibled) {
      if (!state?.data060) {
        const getMapData = async () => {
          try {
            const result = await axios({
              url: "https://kiosk.questx.co.kr/gps?i_serial_no=" + serialNo,
              method: "get",
              timeout: 60000,
              timeoutErrorMessage: "timeout",
            });
            const data060 = result.data.data;
            if (!data060 || data060.length === 0) {
              if (newWindow.ReactNativeWebView) {
                newWindow.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    channel: "onPopupErr",
                    data: "해당 상품의 운행경로 데이터가 생성되지 않았습니다.",
                  })
                );
              } else {
                alert("해당 상품의 운행경로 데이터가 생성되지 않았습니다.");
              }
              onMapCloseClick();
            } else {
              setState({ ...state, data060 });
            }
          } catch (err) {
            if (newWindow.ReactNativeWebView) {
              switch (String(err)) {
                case "Error: timeout":
                  newWindow.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      channel: "onErr",
                      data: "로딩 시간이 초과되었습니다. 잠시 후 다시 시도해 주세요.",
                    })
                  );
                  break;
                default:
                case "Error: Request failed with status code 404":
                  newWindow.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      channel: "onPopupErr",
                      data: String(err),
                    })
                  );
                  break;
              }
            } else {
              if (String(err) === "Error: timeout") {
                alert(
                  "로딩 시간이 초과되었습니다. 잠시 후 다시 시도해 주세요."
                );
              } else {
                alert(String(err));
              }
            }
            onMapCloseClick();
          }
        };

        getMapData();
      } else if (!isMapLoadComplete) {
        const script = document.createElement("script");
        script.innerHTML = ` 
          var map = null;
          var iconMarkerStyle = "z-index: 1; position: relative; bottom: ${GS(
            30
          )}px; left: ${GS(15)}px; color: #ffffff; padding: ${GS(
          5
        )}px; font-size: ${12}px; border-radius: ${GS(3)}px;";
          var carRouteMarkerStyle = "width: ${GS(10)}px; height: ${GS(
          10
        )}px; border-radius: ${GS(
          5
        )}px; background-color: #2361b6; box-shadow: ${GS(1)}px ${GS(1)}px ${GS(
          1
        )}px;";

          function init() {
            map = new Tmapv2.Map('map', {
              width: "${GS(650)}px",
              height: "100%",
              zoomControl: true,
            });
            const routes = ${JSON.stringify(state.data060)};
            const cars = [];
            const bizs = [];
            if (routes.length !== 0) {
              let maxLat = routes[0].car_latitude;
              let maxLng = routes[0].car_longitude;
              let minLat = routes[0].car_latitude;
              let minLng = routes[0].car_longitude;
              for(let i = 0; i < routes.length; i++) {
                if (routes[i].car_latitude > maxLat) maxLat = routes[i].car_latitude;
                if (routes[i].car_latitude < minLat) minLat = routes[i].car_latitude;
                if (routes[i].car_longitude > maxLng) maxLng = routes[i].car_longitude;
                if (routes[i].car_longitude < minLng) minLng = routes[i].car_longitude;
                if (routes[i].car_div === 'CAR') cars.push(routes[i]);
                else bizs.push(routes[i]);
              }
              const maxPos = new Tmapv2.LatLng(maxLat, maxLng);
              const minPos = new Tmapv2.LatLng(minLat, minLng);
              const distance = getDistance(Number(maxLat), Number(maxLng), Number(minLat), Number(minLng));
              let unit = 15 * 0.01
              let zoom = 19;
              while(zoom > 0) {
                if (distance > unit) unit *= 2;
                else break;
                zoom--;
              }
              map.setCenter(new Tmapv2.LatLng((Number(maxLat) + Number(minLat)) / 2, (Number(maxLng) + Number(minLng)) / 2));
              map.setZoom(zoom);
            }
            const carPosList = [];
            const carRoutePosList = [];
            if (cars.length !== 0) {
              let prevCarNumber = cars[0].car_number;
              carPosList.push({
                pos: new Tmapv2.LatLng(cars[0].car_latitude, cars[0].car_longitude),
                carNumber: prevCarNumber,
              });
              for(let i = 1; i < cars.length; i++) {
                if (prevCarNumber !== cars[i].car_number) {
                  prevCarNumber = cars[i].car_number;
                  carPosList.push({
                    pos: new Tmapv2.LatLng(cars[i].car_latitude, cars[i].car_longitude),
                    carNumber: prevCarNumber,
                  });
                } else {
                  carRoutePosList.push(new Tmapv2.LatLng(cars[i].car_latitude, cars[i].car_longitude));
                }
              }
              carPosList.push({
                pos: new Tmapv2.LatLng(cars[cars.length - 1].car_latitude, cars[cars.length - 1].car_longitude),
                carNumber: prevCarNumber,
              });
            }
            const bizPosList = [];
            for(let i = 0; i < bizs.length; i++) {
              bizPosList.push({
                pos: new Tmapv2.LatLng(bizs[i].car_latitude, bizs[i].car_longitude),
                carNumber: bizs[i].car_number
              });
            }
            for(let i = 0; i < carRoutePosList.length; i++) createCarRouteMarker(carRoutePosList[i]);
            for(let i = 0; i < carPosList.length; i++) createCarMarker(carPosList[i]);
            for(let i = 0; i < bizPosList.length; i++) createBizMarker(bizPosList[i]);
          };

          function getDistance(maxLat, maxLng, minLat, minLng) {
            const get = (value) => value * (Math.PI / 180);

            const lat = get(minLat - maxLat);
            const lon = get(minLng - maxLng);
            const x = Math.sin(lat / 2) * Math.sin(lat / 2) + Math.cos(get(maxLat)) * Math.cos(get(minLat)) * Math.sin(lon / 2) * Math.sin(lon / 2);
            return 6371 * 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));
          };

          function createCarRouteMarker(carPos) {
            new Tmapv2.Marker({
              map,
              iconHTML: "<div class='asdasdasd' style='" + carRouteMarkerStyle + "'></div>",
              position: carPos,
            });
          };
      
          function createCarMarker(carPos) {
            new Tmapv2.Marker({
              map,
              iconHTML: "<img style='width: ${GS(30)}px; height: ${GS(
          28
        )}px;' src='${require("../images/icon_delivery.png").default}'/>",
              label: "<span style='" + iconMarkerStyle + "background-color: #313375;'>" + carPos.carNumber + '</span>',
              position: carPos.pos,
            });
          };
          
          function createBizMarker(bizPos) {
            new Tmapv2.Marker({
              map,
              iconHTML: "<img style='width: ${GS(28)}px; height: ${GS(
          31
        )}px;' src='${require("../images/icon_building.png").default}'/>",
              label: "<span style='" + iconMarkerStyle + "background-color: #46414e;'>" + bizPos.carNumber + '</span>',
              position: bizPos.pos,
            });
          };

          init();
        `;

        script.type = "text/javascript";
        script.async = true;
        document.head.appendChild(script);
        setIsMapLoadComplete(true);
      }
    }
  }, [state, isMapVisibled, isMapLoadComplete]);

  useEffect(() => {
    const onScroll = () => {
      const timer = setTimeout(() => {
        if (newWindow.ReactNativeWebView) {
          newWindow.ReactNativeWebView.postMessage(
            JSON.stringify({ channel: "onTouch" })
          );
        }
      }, 1000);
      setTimer(timer);
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      if (timer) clearTimeout(timer);
      window.removeEventListener("scroll", onScroll);
    };
  }, [timer]);

  const onBackgroundClick = useCallback(() => {
    if (newWindow.ReactNativeWebView) {
      newWindow.ReactNativeWebView.postMessage(
        JSON.stringify({ channel: "onTouch" })
      );
    }
  }, []);

  const onLoadMapClick = useCallback(() => {
    setIsMapVisibled(true);
  }, []);

  const onMapCloseClick = useCallback(() => {
    setIsMapVisibled(false);
  }, []);

  const onLoadGraphClick = useCallback(() => {
    setIsGraphVisibled(true);
  }, []);

  const onGraphCloseClick = useCallback(() => {
    setIsGraphVisibled(false);
  }, []);

  const newData080 = useMemo(() => {
    if (!state?.data080?.length) return [];
    let j = -1;
    let newData: any[] = [];
    for (let i = 0; i < state.data080.length; i++) {
      if (state.data080[i].trans_div === "") {
        newData.push({ ...state.data080[i], list: [] });
        j++;
      } else {
        newData[j].list.push(state.data080[i]);
      }
    }
    return newData;
  }, [state]);

  console.log(state);

  return (
    <>
      <style>
        {(isMapVisibled || isGraphVisibled) &&
          `
          html,
          body,
          #root {
            overflow: hidden;
          }
        `}
      </style>
      <div
        onClick={onBackgroundClick}
        style={{
          flex: 1,
          padding: GS(33.5),
        }}
      >
        {state && (
          <>
            <img
              onClick={onLoadMapClick}
              src={require("../images/btn_map.png").default}
              style={{
                width: GS(170),
                height: GS(170),
                right: (window.innerWidth - fixSize) / 2 + GS(30),
                bottom: GS(70),
                position: "fixed",
                zIndex: 1,
              }}
            ></img>
            <img
              onClick={onLoadGraphClick}
              src={require("../images/btn_graph.png").default}
              style={{
                width: GS(170),
                height: GS(170),
                right: (window.innerWidth - fixSize) / 2 + GS(30),
                bottom: GS(240),
                position: "fixed",
                zIndex: 1,
              }}
            ></img>
            <div
              style={{
                alignItems: "flex-end",
              }}
            >
              <span
                style={{
                  ...GTS(23, "nsr"),
                  color: "#a4a4a4",
                  height: GS(30),
                }}
              >
                {/* {formatDate({ style: "yyyy. mm. dd. hh:mm", dateStr: dateToDateStr(new Date()) })} 기준 */}
              </span>
            </div>
            <div>
              <span
                style={{
                  ...GTS(42, "nsb"),
                  color: "#191919",
                }}
              >
                {state.data010.send_good}
              </span>
            </div>
            <div>
              <span
                style={{
                  ...GTS(27, "nsr"),
                  color: "#191919",
                  marginTop: GS(15),
                }}
              >
                {state.data010.send_origin}
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <img
                alt="distribution_time"
                style={{
                  width: GS(626),
                  height: GS(136),
                  marginTop: GS(40),
                  marginLeft: GS(10),
                  marginRight: GS(10),
                }}
                src={require("../images/distribution_time.png").default}
              />
            </div>
            <div
              style={{
                margin: GS(10),
                position: "relative",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <span
                  style={{
                    color: "#455a64",
                    ...GTS(21, "nsr"),
                  }}
                >
                  {formatDate({
                    style: "yyyy. mm. dd. hh:mm",
                    dateStr: state.data010.send_start_time,
                  })}
                </span>
              </div>
              <div
                style={{
                  flex: 1,
                  position: "relative",
                  bottom: GS(120),
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#191919",
                      ...GTS(24, "nsr"),
                    }}
                  >
                    물류시간
                  </span>
                </div>
                <div
                  style={{
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#005eec",
                      ...GTS(30, "nsm"),
                    }}
                  >
                    {state.data010.send_time}
                  </span>
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                }}
              >
                <span
                  style={{
                    color: "#455a64",
                    ...GTS(21, "nsr"),
                  }}
                >
                  {formatDate({
                    style: "yyyy. mm. dd. hh:mm",
                    dateStr: state.data010.send_end_time,
                  })}
                </span>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
              }}
            >
              {/* <div
                onClick={onLoadGraphClick}
                style={{
                  width: GS(400),
                  cursor: "pointer",
                  height: GS(100),
                  backgroundColor: GC().main,
                  borderRadius: GS(15),
                  boxShadow: `#888888 ${GS(5)}px ${GS(5)}px ${GS(5)}px`,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#ffffff",
                    ...GTS(40, "nsb"),
                  }}
                >
                  그래프보기
                </span>
              </div> */}
              {/* <div
                onClick={onLoadMapClick}
                style={{
                  width: GS(400),
                  cursor: "pointer",
                  height: GS(100),
                  borderColor: GC().main,
                  borderStyle: "solid",
                  borderWidth: GS(2),
                  borderRadius: GS(15),
                  boxShadow: `#888888 ${GS(5)}px ${GS(5)}px ${GS(5)}px`,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: GS(20),
                }}
              >
                <span
                  style={{
                    color: GC().main,
                    ...GTS(40, "nsb"),
                  }}
                >
                  지도보기
                </span>
              </div> */}
            </div>
            <span
              style={{
                marginTop: GS(50),
                color: "#191919",
                ...GTS(34, "nsb"),
              }}
            >
              물류정보
            </span>
            <div
              style={{
                flex: 1,
                padding: GS(30),
                paddingTop: GS(50),
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: GS(20),
                  height: GS(40 + 80 * newData080.length),
                  borderRadius: GS(10),
                  backgroundColor: "#f0f4f9",
                }}
              >
                {newData080.map((data, idx) => {
                  return (
                    <div key={idx}>
                      <span
                        style={{
                          marginBottom: GS(80),
                          borderRadius: GS(10),
                          width: GS(20),
                          height: GS(20),
                          backgroundColor:
                            idx === 0 || idx === newData080.length - 1
                              ? "#005eec"
                              : "#90a4ae",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  flex: 1,
                  top: GS(-10),
                  position: "relative",
                }}
              >
                {newData080.map((data, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        height: GS(100),
                        marginLeft: GS(40),
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <span
                          style={{
                            width: GS(60),
                            color: "#191919",
                            ...GTS(25, "nsr"),
                          }}
                        >
                          {formatDate({
                            dateStr: data.trans_time,
                            style: "hh:mm",
                          })}
                        </span>
                        <span
                          style={{
                            color: "#191919",
                            width: GS(115),
                            ...GTS(25, "nsb"),
                            marginLeft: GS(20),
                          }}
                        >
                          {data.trans_state}
                        </span>
                        <span
                          style={{
                            color: "#191919",
                            ...GTS(25, "nsm"),
                            marginLeft: GS(20),
                          }}
                        >
                          {data.trans_loc}
                        </span>
                      </div>
                      {data.list.map((subData: any, idx: number) => {
                        return (
                          <span
                            key={idx}
                            style={{
                              ...GTS(20, "nsr"),
                              color: "#9b9b9b",
                              marginTop: GS(17),
                            }}
                          >
                            <span
                              style={{ display: "inline-block", width: GS(72) }}
                            >
                              [{subData.trans_div}]
                            </span>
                            &nbsp;&nbsp;
                            <span
                              style={{
                                display: "inline-block",
                                width: GS(125),
                              }}
                            >
                              {subData.trans_elapsed_time}
                            </span>
                            &nbsp;&nbsp;
                            {subData.trans_loc}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <Modal isVisible={isMapVisibled} setIsVisible={setIsMapVisibled}>
          <div
            style={{
              backgroundColor: "#ffffff",
              width: GS(650),
              height: GS(650),
              boxShadow: `#444444 ${GS(10)}px ${GS(10)}px ${GS(10)}px`,
              borderRadius: GS(20),
            }}
          >
            <style>
              {`
                #map > div {
                  border-top-left-radius: ${GS(20)}px;
                  border-top-right-radius: ${GS(20)}px;
                }
              `}
            </style>
            <div
              id="map"
              style={{
                width: GS(650),
                height: GS(650),
                justifyContent: "center",
                alignItems: "center",
                borderTopLeftRadius: GS(20),
                borderTopRightRadius: GS(20),
              }}
            >
              {!isMapLoadComplete && (
                <span
                  style={{
                    color: "#ffffff",
                    ...GTS(23, "nsm"),
                    padding: GS(60),
                  }}
                >
                  운행경로를 불러오는 과정에서 로딩시간이 오래 걸릴 수 있습니다.
                  잠시만 기다려 주세요.
                </span>
              )}
            </div>
            <div
              onClick={onMapCloseClick}
              style={{
                height: GS(130),
                width: GS(650),
                backgroundColor: "#ffffff",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderBottomLeftRadius: GS(20),
                borderBottomRightRadius: GS(20),
              }}
            >
              <span
                style={{
                  color: "#191919",
                  ...GTS(30, "nsb"),
                }}
              >
                닫기
              </span>
            </div>
          </div>
        </Modal>
        <Modal isVisible={isGraphVisibled} setIsVisible={setIsGraphVisibled}>
          <div
            style={{
              backgroundColor: "#ffffff",
              width: GS(650),
              height: GS(600),
              boxShadow: `#444444 ${GS(10)}px ${GS(10)}px ${GS(10)}px`,
              borderTopLeftRadius: GS(20),
              borderTopRightRadius: GS(20),
              borderBottomLeftRadius: GS(20),
              borderBottomRightRadius: GS(20),
            }}
          >
            <div
              id={!isGraphLoadComplete ? "graph" : ""}
              style={{
                width: GS(650),
                borderTopLeftRadius: GS(20),
                borderTopRightRadius: GS(20),
                height: GS(550),
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                borderBottomWidth: !isGraphLoadComplete ? 0 : GS(1),
                borderBottomColor: "#444444",
                borderBottomStyle: "solid",
              }}
            >
              {!isGraphLoadComplete ? (
                <span
                  style={{
                    color: "#ffffff",
                    ...GTS(23, "nsm"),
                    padding: GS(60),
                  }}
                >
                  온습도 정보를 불러오는 과정에서 로딩시간이 오래 걸릴 수
                  있습니다. 잠시만 기다려 주세요.
                </span>
              ) : (
                <>
                  <div
                    style={{
                      width: GS(530),
                      height: GS(250),
                      position: "relative",
                      top: GS(-30),
                    }}
                  >
                    {/* 온도 줄 */}
                    {state.data020.temps.map((temp: string, idx: number) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            position: "absolute",
                            height: GS(1),
                            width: GS(530),
                            top: GS((250 / 4) * idx),
                            backgroundColor: "#0079d788",
                          }}
                        ></div>
                      );
                    })}
                    {/* 온도 줄 */}
                    {state.data020.humis.map((humi: string, idx: number) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            position: "absolute",
                            height: GS(1),
                            width: GS(530),
                            top: GS((250 / 4) * idx + 31),
                            backgroundColor: "#d32f2f88",
                          }}
                        ></div>
                      );
                    })}
                    {/* 온도 줄 넘버 */}
                    {state.data020.temps.map((temp: string, idx: number) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            position: "absolute",
                            top: GS((250 / 4) * idx - 12),
                            left: -GS(60),
                          }}
                        >
                          <span
                            style={{
                              color: "#0079d7",
                              ...GTS(15, "nsm"),
                              textAlign: "right",
                              width: GS(50),
                            }}
                          >
                            {temp}
                          </span>
                        </div>
                      );
                    })}
                    {/* 습도 줄 넘버 */}
                    {state.data020.humis.map((humi: string, idx: number) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            position: "absolute",
                            alignItems: "flex-end",
                            top: GS((190 / 3) * idx - 6 + 25),
                            right: -GS(40),
                          }}
                        >
                          <span
                            style={{
                              color: "#d32f2f",
                              ...GTS(15, "nsm"),
                              textAlign: "right",
                              width: GS(50),
                            }}
                          >
                            {humi}
                          </span>
                        </div>
                      );
                    })}
                    {/* 온도 그래프 */}
                    <div
                      style={{
                        position: "absolute",
                        top: GS(250 / 4) - GS(10),
                        // left: -GS(700 / 12),
                        left: -GS(25),
                      }}
                    >
                      <XYPlot
                        width={GS(550)}
                        height={GS(250 - 150 / 4) + GS(27)}
                      >
                        <LineMarkSeries
                          data={state.data020.tempList.map(
                            (temp: string, idx: number) => {
                              return {
                                x: idx,
                                y: temp,
                              };
                            }
                          )}
                          size={GS(5)}
                          lineStyle={{ fill: "#00000000" }}
                          // curve={"curveMonotoneX"}
                          fill="#0079d7"
                          stroke="#0079d7"
                        />
                      </XYPlot>
                    </div>
                    {/* 습도 그래프 */}
                    <div
                      style={{
                        position: "absolute",
                        top: GS(25) - GS(4),
                        left: -GS(25),
                      }}
                    >
                      <XYPlot width={GS(550)} height={GS(200 - 25 + 1)}>
                        <LineMarkSeries
                          data={state.data020.humiList.map(
                            (humi: string, idx: number) => {
                              return { x: idx, y: humi };
                            }
                          )}
                          style={{
                            color: "#d32f2f",
                          }}
                          size={GS(5)}
                          lineStyle={{ fill: "#00000000" }}
                          // curve={"curveMonotoneX"}
                          fill="#d32f2f"
                          stroke="#d32f2f"
                        />
                      </XYPlot>
                    </div>
                    {/* idxs */}
                    <div
                      style={{
                        position: "absolute",
                        flexDirection: "row",
                        top: GS(260),
                        left: GS(7),
                      }}
                    >
                      {state.data020.idxs.map((idxStr: string, idx: number) => {
                        return (
                          <div
                            style={{
                              width: GS(500 / (state.data020.idxs.length - 1)),
                            }}
                            key={idx}
                          >
                            <span
                              style={{
                                color: "#888888",
                                ...GTS(15, "nsb"),
                                width: "100%",
                              }}
                            >
                              {idxStr}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    {/* 출발 도착 시간 */}
                    <div
                      style={{
                        position: "absolute",
                        flexDirection: "row",
                        top: GS(300),
                        width: GS(595),
                        left: GS(-30),
                      }}
                    >
                      <div style={{ flex: 1, alignItems: "flex-start" }}>
                        <span
                          style={{
                            color: "#191919",
                            ...GTS(17, "nsm"),
                            textAlign: "center",
                            whiteSpace: "pre-line",
                            lineHeight: "125%",
                          }}
                        >
                          (출발)
                          {"\n"}
                          {state.data020.startTime.substr(0, 13)}
                          {"\n"}
                          {state.data020.startTime.substr(13)}
                        </span>
                      </div>
                      <div style={{ flex: 1, alignItems: "flex-end" }}>
                        <span
                          style={{
                            color: "#191919",
                            ...GTS(17, "nsm"),
                            textAlign: "center",
                            whiteSpace: "pre-line",
                            lineHeight: "125%",
                          }}
                        >
                          (도착)
                          {"\n"}
                          {state.data020.endTime.substr(0, 13)}
                          {"\n"}
                          {state.data020.endTime.substr(13)}
                        </span>
                      </div>
                    </div>
                    {/* 온도 습도 색 구분 */}
                    <div
                      style={{
                        position: "absolute",
                        flexDirection: "row",
                        top: GS(-50),
                        width: GS(595),
                        justifyContent: "flex-end",
                        left: GS(-30),
                      }}
                    >
                      <div
                        style={{
                          marginRight: GS(20),
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            width: GS(32),
                            height: GS(32),
                            borderRadius: GS(17.5),
                            backgroundColor: "#0079d7",
                          }}
                        ></div>
                        <span
                          style={{
                            marginLeft: GS(5),
                            color: "#191919",
                            ...GTS(22, "nsb"),
                            alignSelf: "center",
                          }}
                        >
                          온도
                        </span>
                      </div>
                      <div style={{ flexDirection: "row" }}>
                        <div
                          style={{
                            width: GS(32),
                            height: GS(32),
                            borderRadius: GS(17.5),
                            backgroundColor: "#d32f2f",
                          }}
                        ></div>
                        <span
                          style={{
                            marginLeft: GS(5),
                            color: "#191919",
                            ...GTS(22, "nsb"),
                            alignSelf: "center",
                          }}
                        >
                          습도
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              onClick={onGraphCloseClick}
              style={{
                height: GS(130),
                width: GS(650),
                backgroundColor: "#ffffff",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderTopLeftRadius: GS(20),
                borderTopRightRadius: GS(20),
                borderBottomLeftRadius: GS(20),
                borderBottomRightRadius: GS(20),
              }}
            >
              <span
                style={{
                  color: "#191919",
                  ...GTS(30, "nsb"),
                }}
              >
                닫기
              </span>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

const formatGraphData = (data: any) => {
  let data020;
  if (data.length > 0) {
    let list = [];
    if (data.length > 15) {
      const mulNum = data.length / 14;
      for (let i = 0; i < 14; i++) list.push(data[Math.floor(mulNum * i)]);
      list.push(data[data.length - 1]);
    } else {
      list = data;
    }
    if (list.length !== 0) {
      let maxTemp;
      let minTemp;
      let maxHumi;
      let minHumi;
      for (let i = 0; i < list.length; i++) {
        if (maxTemp === undefined || Number(list[i].hist_temp) > maxTemp) {
          maxTemp = Number(list[i].hist_temp);
        }
        if (minTemp === undefined || Number(list[i].hist_temp) < minTemp) {
          minTemp = Number(list[i].hist_temp);
        }
        if (maxHumi === undefined || Number(list[i].hist_humi) > maxHumi) {
          maxHumi = Number(list[i].hist_humi);
        }
        if (minHumi === undefined || Number(list[i].hist_humi) < minHumi) {
          minHumi = Number(list[i].hist_humi);
        }
      }
      if (
        maxTemp !== undefined &&
        minTemp !== undefined &&
        maxHumi !== undefined &&
        minHumi !== undefined
      ) {
        const tempStartTime = data[0].hist_time;
        const tempEndTime = data[data.length - 1].hist_time;
        const startTime =
          tempStartTime.substr(0, 4) +
          "년 " +
          tempStartTime.substr(4, 2) +
          "월 " +
          tempStartTime.substr(6, 2) +
          "일 " +
          tempStartTime.substr(8, 2) +
          "시 " +
          tempStartTime.substr(10, 2) +
          "분";
        const endTime =
          tempEndTime.substr(0, 4) +
          "년 " +
          tempEndTime.substr(4, 2) +
          "월 " +
          tempEndTime.substr(6, 2) +
          "일 " +
          tempEndTime.substr(8, 2) +
          "시 " +
          tempEndTime.substr(10, 2) +
          "분";
        const temps = [];
        const tempValue = maxTemp - minTemp;
        temps.push(Number(maxTemp + (tempValue / 3) * 1).toFixed(1));
        temps.push(Number(maxTemp).toFixed(1));
        temps.push(Number(maxTemp - (tempValue / 3) * 1).toFixed(1));
        temps.push(Number(maxTemp - (tempValue / 3) * 2).toFixed(1));
        temps.push(Number(maxTemp - tempValue).toFixed(1));
        const humis = [];
        const humivalue = maxHumi - minHumi;
        humis.push(Number(maxHumi).toFixed(1));
        humis.push(Number(maxHumi - humivalue / 2).toFixed(1));
        humis.push(Number(maxHumi - tempValue).toFixed(1));
        humis.push(
          Number(maxHumi - tempValue - (humivalue / 2) * 1).toFixed(1)
        );
        const tempList = [];
        const humiList = [];
        const idxs = [];
        for (let i = 0; i < list.length; i++) {
          tempList.push(list[i].hist_temp);
          humiList.push(list[i].hist_humi);
          idxs.push(twoChar(i));
        }
        data020 = {
          temps,
          humis,
          tempList,
          humiList,
          idxs,
          startTime,
          endTime,
        };
      }
    }
  }
  return data020;
};
