type T_notoSansFont = "nsb" | "nsl" | "nsm" | "nsr";

type T_gmarketSansFont = "gsb" | "gsl" | "gsm";

type T_koPubBatangFont = "kpbb" | "kpbl" | "kpbm";

type T_font = T_notoSansFont | T_gmarketSansFont | T_koPubBatangFont;

const getFont = (font?: T_font) => {
  switch (font) {
    case "kpbb":
      return {
        fontFamily: "KoPubBatangBold",
      };
    case "kpbl":
      return {
        fontFamily: "KoPubBatangLight",
      };
    case "kpbm":
      return {
        fontFamily: "KoPubBatangMedium",
      };
    case "gsb":
      return {
        fontFamily: "GmarketSansBold",
      };
    case "gsl":
      return {
        fontFamily: "GmarketSansLight",
      };
    case "gsm":
      return {
        fontFamily: "GmarketSansMedium",
      };
    // case "nsvb":
    //   return {
    //     fontFamily: "Noto Sans KR",
    //     fontWeight: 900,
    //   };
    case "nsb":
      return {
        fontFamily: "Noto Sans KR",
        fontWeight: 700,
      };
    case "nsm":
      return {
        fontFamily: "Noto Sans KR",
        fontWeight: 500,
      };
    case "nsr":
      return {
        fontFamily: "Noto Sans KR",
        fontWeight: 400,
      };
    case "nsl":
      return {
        fontFamily: "Noto Sans KR",
        fontWeight: 300,
      };
    // case "nst":
    //   return {
    //     fontFamily: "Noto Sans KR",
    //     fontWeight: 100,
    //   };
  }
};

function getColors() {
  return {
    main: "#005eec",
    positive: "#0e82ff",
    negative: "#f94d60",
  };
}
export const fixSize = Number(window.innerWidth) > 700 ? 700 : window.innerWidth;

function getSize(size: "maxWidth" | "maxHeight" | number) {
  switch (size) {
    case "maxWidth":
      return fixSize;
    case "maxHeight":
      return window.innerHeight;
    default:
      return (fixSize / 720) * size;
  }
}

function getTextStyle(size: number, font?: T_font) {
  const fontSize = (fixSize / 780) * size;
  return {
    fontSize,
    ...getFont(font),
  };
}

export const GC = getColors;
export const GS = getSize;
export const GTS = getTextStyle;
