import { Route, Routes } from "react-router";
import GoodsInfo from "./pages/GoodsInfo";
import Home from "./pages/Home";
import { GS } from "./utils/style";

export default function App() {
  return (
    <div
      style={{
        flex: 1,
        alignItems: "center",
      }}
    >
      <Routes>
        <Route path="/goods-info/:serialNo" element={<GoodsInfo />} />
        <Route path="/mscc" element={<GoodsInfo />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}
