import { useCallback, useState } from "react";

type T_props = {
  children: JSX.Element;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

export default function Modal(props: T_props) {
  const onOverlayClick = useCallback(
    (evt: React.MouseEvent) => {
      const target: any = evt.target;
      if (target.id === "Modal" && target.isModalMouseDown) {
        target.isModalMouseDown = false;
        props.setIsVisible(false);
      }
    },
    [props]
  );

  const onMouseDown = useCallback((evt: React.MouseEvent) => {
    const target: any = evt.target;
    target.isModalMouseDown = target.id === "Modal";
  }, []);

  return (
    <div
      id="Modal"
      className="noDrag"
      onMouseDown={onMouseDown}
      onClick={onOverlayClick}
      style={{
        zIndex: 1,
        top: 0,
        left: 0,
        display: props.isVisible ? "flex" : "none",
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#00000088",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
}
