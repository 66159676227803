export const dateToDateObj = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
  };
};

export function twoChar(value: number) {
  return value < 10 ? "0" + String(value) : String(value);
}

export function dateToDateStr(date: Date) {
  const year = date.getFullYear();
  const month = twoChar(date.getMonth() + 1);
  const day = twoChar(date.getDate());
  const hour = twoChar(date.getHours());
  const minute = twoChar(date.getMinutes());
  const second = twoChar(date.getSeconds());
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function dateStrToDateArr(dateStr: string) {
  let text = "";
  if (dateStr.split(" ").length === 2) text = " ";
  else text = " ";
  const [date, time] = dateStr.split(text);
  const [year, month, day] = date.split("-");
  const [hour, minute, second] = time.split(":");
  return [year, month, day, hour, minute, second];
}

export function dateStrToDate(dateStr: string) {
  const [year, month, day, hour, minute, second] = dateStrToDateArr(dateStr);
  return new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second));
}

type T_formatDateParams = {
  isSimple?: boolean;
  dateStr: string;
  style: "hh:mm" | "yyyy. mm. dd. hh:mm";
};

export function formatDate(params: T_formatDateParams) {
  const dateArr = dateStrToDateArr(params.dateStr);
  const [year, month, day, hour, minute, second] = dateArr;
  if (params.isSimple) {
    const curTime = new Date().getTime() / 1000;
    const dateTime = dateStrToDate(params.dateStr).getTime() / 1000;
    const remainTime = curTime - dateTime;
    const remainDay = Math.floor(remainTime / 60 / 60 / 24);
    if (remainDay >= 0 && remainDay < 1) {
      const lastHour = Math.floor((remainTime / 60 / 60) % 24);
      const lastMinute = Math.floor((remainTime / 60) % 60);
      const lastSecond = Math.floor(remainTime % 60);
      if (lastHour !== 0) return `${String(lastHour)}시간 전`;
      else if (lastMinute !== 0) return `${String(lastMinute)}분 전`;
      else if (lastSecond !== 0) return `${String(lastSecond)}초 전`;
      else return "방금";
    }
  }
  switch (params.style) {
    case "hh:mm":
      return `${hour}:${minute}`;
    case "yyyy. mm. dd. hh:mm":
      return `${year}. ${month}. ${day}. ${hour}:${minute}`;
  }
}
